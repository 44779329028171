import { Button } from '@repo/ui/button';
import Logspot from '@logspot/web';

type TrackButtonProps = {
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'icon';
  variant?: 'primary' | 'outline';
  className?: string;
  trackEvent?: string;
  children: React.ReactNode;
};

export const TrackButton = ({
  size,
  variant,
  className,
  trackEvent,
  children,
}: TrackButtonProps) => {
  return (
    <Button
      size={size}
      variant={variant}
      className={className}
      onClick={() =>
        trackEvent &&
        (console.log('trackEvent', trackEvent),
        Logspot.track({ event: trackEvent }))
      }
    >
      {children}
    </Button>
  );
};
